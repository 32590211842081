import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

const TITLE = <Link to="/">ZROW COACHING</Link>;
const ROUTE_PREFIX = "/site";
const pages = [
  { page: "HOME", link: "/" },
  { page: "ABOUT", link: `${ROUTE_PREFIX}/about` },
  { page: "SERVICES", link: `${ROUTE_PREFIX}/services` },
  { page: "TESTIMONIALS", link: `${ROUTE_PREFIX}/testimonials` },
  // { page: "Socials", link: "/" },
  { page: "CONTACT", link: `${ROUTE_PREFIX}/contact` },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar className="bg-dark-blue static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box className="flex flex-grow md:hidden">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              className="block md:hidden"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map(({ page, link }) => (
                <Link key={page} to={link}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography className="text-center">{page}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            className="mr-2 flex flex-grow font-mono font-bold tracking-[.3rem] text-inherit no-underline md:hidden"
            noWrap
            component="a"
          >
            {TITLE}
          </Typography>

          {/* BREAKPOINT */}

          <Typography
            variant="h6"
            className="mr-2 hidden font-mono font-bold tracking-[.3rem] text-inherit no-underline md:flex"
            noWrap
            component="a"
          >
            {TITLE}
          </Typography>

          <Box className="hidden flex-grow md:flex">
            {pages.map(({ page, link }) => (
              <Link key={page} to={link}>
                <Button className="my-2 block text-white" onClick={handleCloseNavMenu}>
                  {page}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
